import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import Scroll from "../Scroll";
import Marquee from "react-fast-marquee";

import "./style.scss";

const banner = {
  animate: {
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.1,
    },
  },
};

const letterAni = {
  initial: { y: 200 },
  animate: {
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1,
    },
  },
};

const AnimatedLetters = ({ title }) => (
  <motion.div variants={banner} initial="initial" animate="animate">
    {[...title].map((letter, i) => (
      <motion.span className="row-letter" variants={letterAni} key={i}>
        {letter}
      </motion.span>
    ))}
  </motion.div>
);

const BannerRowTop = ({ title }) => {
  return (
    <div className={"banner-row"}>
      <AnimatedLetters title={title} />
    </div>
  );
};

const Home = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  useEffect(() => {
    if (modalOpen) {
      const body = document.body;
      body.style.overflowY = "hidden";
    } else {
      const body = document.body;
      body.style.overflowY = "visible";
    }
  }, [modalOpen]);

  return (
    <div className="Home">
      <div>
        <BannerRowTop title={`LANE`} />
        <motion.div
          className="Home__content--title--desc"
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 0.4,
          }}
        >
          <span className="row-message">He who dares...</span>{" "}
        </motion.div>
      </div>
      <Marquee gradient={false} speed={100} loop={0}>
        <div className="Home__marquee">
          REACT.JS NEXT.JS REACT-NATIVE JAVASCRIPT TYPESCRIPT REDUX ZUSTAND CSS
          SASS
        </div>
      </Marquee>
      <motion.div
        initial={{ opacity: 0, x: 40 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{
          ease: "easeInOut",
          duration: 1,
          delay: 1,
        }}
      >
        <motion.div
          className="Home__contact"
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.97 }}
          onClick={() => (modalOpen ? close() : open())}
        >
          contact( )
        </motion.div>
      </motion.div>

      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {modalOpen && <Modal modalOpen={modalOpen} handleClose={close} />}
      </AnimatePresence>

      <motion.div
        className="Home__scroll"
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{
          ease: "easeInOut",
          duration: 1,
          delay: 1.5,
        }}
      >
        <Scroll />
      </motion.div>
    </div>
  );
};

export default Home;
