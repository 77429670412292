import React, { useEffect, useRef } from "react";
import { data } from "../../assets/work";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

import "./style.scss";
import { motion } from "framer-motion";

gsap.registerPlugin(ScrollTrigger);

const Work = () => {
  const revealRefs = useRef([]);
  revealRefs.current = [];

  useEffect(() => {
    revealRefs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        {
          autoAlpha: 0,
          scale: 0.7,
        },
        {
          // duration: 1,
          autoAlpha: 1,
          scale: 1,
          ease: "none",
          scrollTrigger: {
            trigger: el,
            start: "top 80%",
            toggleActions: "play none none reverse",
            // markers: true,
            // scrub: true,
          },
        }
      );
    });
  }, []);

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  return (
    <div className="Work">
      <p className="Work__title">FEATURED</p>
      <div className="Work__container">
        {data.map(
          (x, i) =>
            x.id && (
              <div className="Work__container--card" key={i} ref={addToRefs}>
                <a
                  href={x.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "white" }}
                >
                  <img
                    src={x.thumbnail}
                    className="Work__container--card--img"
                    alt="thumbnail"
                  />
                  <div className="Work__container--card--desc">
                    <h1 className="Work__container--card--desc--title">
                      {x.title.toUpperCase()}
                    </h1>

                    <p className="Work__container--card--desc--desc">
                      {x.desc}
                    </p>
                  </div>
                </a>
                <motion.a
                  href={x.github}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="Work__container--card--github"
                  whileHover={{ scale: 1.5, color: "#3bdbd8" }}
                  whileTap={{ scale: 0.97 }}
                >
                  <i className="fa-brands fa-github" size="30" />
                </motion.a>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default Work;
