import "./App.scss";
import BackgroundAnimation from "./components/BackgroundAnimation";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import FeaturedWork from "./components/FeaturedWork";
import ProgressBar from "./components/ProgressBar";
import Avatar from "./components/Avatar";

function App() {
  console.info("\nDesigned and Developed By Milan Milenkovic 🧠");

  return (
    <div className="App">
      {/* <div className="Home__background">
        <BackgroundAnimation />
      </div>
      <div className="App__container">
        <ProgressBar />
        <Header />
        <Home />
        <FeaturedWork />
        <Footer />
      </div> */}
      <Avatar />
      <a style={{ color: "white", fontSize: "40px", marginTop: "2rem" }} href="https://github.com/milanm-dev" target="_blank" rel="noopener noreferrer">
        <i className="fa-brands fa-github" size="40" />
      </a>
    </div>
  );
}

export default App;
