import instagram from '../images/instagram.png';
import tmdb from '../images/tmdb.png';
import mern from '../images/mern.png';
import crypto from '../images/crypto.png';
import thundershop from '../images/thundershop.png';
// import moviedborg from "../images/moviedborg.png";
import musicapp from '../images/musicapp.png';
import covid from '../images/covid.png';
// import hackernews from "../images/hackernews.png";

export const data = [
  {
    id: 1,
    title: 'Instagram Clone',
    thumbnail: `${instagram}`,
    desc: 'Built with React.js, Apollo, GraphQL, Hasura, Material-UI, firebase.',
    link: 'https://instalane.netlify.app',
    github: 'https://github.com/milanm-dev/instagram-clone',
  },
  {
    id: 2,
    title: 'Movie App',
    thumbnail: `${tmdb}`,
    desc: 'Movie app powered by TMDb API. Built with React.js, redux and SCSS',
    link: 'https://tmdb-react-seven.vercel.app/',
    github: 'https://github.com/milanm-dev/tmdb-react',
  },
  {
    id: 3,
    title: 'CryptoTrack',
    thumbnail: `${crypto}`,
    desc: "Built with react, redux-toolkit, sass. Bing News Search & CoinRanking API's.",
    link: 'https://cryptotrack73.vercel.app/',
    github: 'https://github.com/milanm-dev/react-cryptotrack',
  },
  {
    id: 4,
    title: 'MERN E-commerce',
    thumbnail: `${mern}`,
    desc: 'Built with mern stack. Fully functional shop with admin protected routes and safe paypal payment.',
    link: 'https://milanecommerce.herokuapp.com/',
    github: 'https://github.com/milanm-dev/mern-eCommerce',
  },
  {
    id: 5,
    title: 'Thunder Tech Shop',
    thumbnail: `${thundershop}`,
    desc: 'Tech e-commerce app. CRUD protected routes. Built with React, Redux, Firebase and Stripe.',
    link: 'https://romantic-tesla-d09dc6.netlify.app/',
    github: 'https://github.com/milanm-dev/react-firebase-ecommerce',
  },
  // {
  //   id: 6,
  //   title: "Movie-db",
  //   thumbnail: `${moviedborg}`,
  //   desc: "API: themoviedb.org. Built with React.js, Material-UI, firebase and redux.",
  //   link: "https://moviedborg.netlify.app/",
  //   github: "https://github.com/milanm-dev/moviedb-firebase",
  // },
  {
    id: 7,
    title: 'Music Share App',
    thumbnail: `${musicapp}`,
    desc: 'Built with React.js, GraphQL, Context, Material-UI, React-Player, Apollo and Firebase.',
    link: 'https://music-share-app.web.app/',
    github: 'https://github.com/milanm-dev/music-share',
  },
  {
    id: 8,
    title: 'COVID-19 Data Tracker',
    thumbnail: `${covid}`,
    desc: 'API: covid19.mathdro.id/api. Built with React.js, Axios, Material-UI & React-charts',
    link: 'https://covid-19-app-285bd.web.app/',
    github: 'https://github.com/milanm-dev/covid-19-tracker',
  },
  // {
  //   id: 9,
  //   title: "Hacker News Clone",
  //   thumbnail: `${hackernews}`,
  //   desc: "I used React.js and firebase. Such a hacker, much wow!",
  //   link: "https://hacker-news-clone-1af48.web.app/new/1",
  //   github: "https://github.com/milanm-dev/hacker-news-clone",
  // },
];
